import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c(VApp, [_c('inc-header', {
    attrs: {
      "id": "menu",
      "project": {
        name: '监护人信息'
      },
      "mobile": _vm.mobile,
      "menu": _vm.menu,
      "help": false
    }
  }), _c(VContainer, {
    attrs: {
      "app": "",
      "fluid": ""
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-sm": "1"
    }
  }, [_c(VAlert, {
    attrs: {
      "outlined": "",
      "type": "info",
      "prominent": "",
      "border": "left"
    }
  }, [_vm._v(" 用户信息的姓名、身份证号码必须是真实信息，否则无法完成单位身份的关联。将影响帐号的正常使用，而且，用户在录入姓名、身份证号码后，将无法修改。请务必谨慎检查内容是否正确。 ")])], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-sm": "1"
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c(VCard, [_c(VSystemBar, {
    attrs: {
      "color": "indigo lighten-2",
      "dark": ""
    }
  }), _c(VToolbar, {
    attrs: {
      "elevation": "1",
      "color": ""
    }
  }, [_c(VIcon, {
    staticClass: "mr-4 text-h4",
    attrs: {
      "color": "orange"
    }
  }, [_vm._v("mdi-card-account-details-outline")]), _c(VToolbarTitle, [_vm._v("用户信息")])], 1), _c(VCardText, {
    staticClass: "mt-4 px-6"
  }, [_c(VTextField, {
    attrs: {
      "label": "UID",
      "disabled": "",
      "outlined": ""
    },
    model: {
      value: _vm.user.us,
      callback: function ($$v) {
        _vm.$set(_vm.user, "us", $$v);
      },
      expression: "user.us"
    }
  }), _c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "USERID",
      "disabled": ""
    },
    model: {
      value: _vm.user.userid,
      callback: function ($$v) {
        _vm.$set(_vm.user, "userid", $$v);
      },
      expression: "user.userid"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "姓名",
      "readonly": _vm.disabled,
      "outlined": ""
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "身份证",
      "readonly": _vm.disabled,
      "outlined": ""
    },
    model: {
      value: _vm.user.idcard,
      callback: function ($$v) {
        _vm.$set(_vm.user, "idcard", $$v);
      },
      expression: "user.idcard"
    }
  }), _c(VRadioGroup, {
    attrs: {
      "label": "性别",
      "readonly": _vm.disabled,
      "outlined": ""
    },
    model: {
      value: _vm.user.sex,
      callback: function ($$v) {
        _vm.$set(_vm.user, "sex", $$v);
      },
      expression: "user.sex"
    }
  }, [_c(VRadio, {
    attrs: {
      "label": "男",
      "value": 1
    }
  }), _c(VRadio, {
    attrs: {
      "label": "女",
      "value": 2
    }
  })], 1)], 1), _vm.disabled ? _c(VAlert, {
    staticClass: "mx-2",
    attrs: {
      "type": "error",
      "dense": ""
    }
  }, [_vm._v(" 内容已被锁定 ")]) : _vm._e(), _c(VCardText, {
    attrs: {
      "align": "center"
    }
  }, [_c(VBtn, {
    attrs: {
      "color": "success",
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.dialog = true;
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-check")]), _vm._v(" 保存信息 ")], 1)], 1), _vm._v("` ")], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [(_vm.teachers && Object.keys(_vm.teachers).length > 0 ? true : false) ? _c(VCard, {
    staticClass: "mb-6"
  }, [_c(VSystemBar, {
    attrs: {
      "color": "indigo lighten-2",
      "dark": ""
    }
  }), _c(VToolbar, {
    attrs: {
      "color": "deep-purple lighten-4",
      "elevation": "1"
    }
  }, [_c(VIcon, {
    staticClass: "mr-4"
  }, [_vm._v("mdi-account-network-outline")]), _c(VToolbarTitle, [_vm._v("相关联的工作身份")])], 1), _c(VSimpleTable, {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', _vm._l(_vm.teachers, function (ts, x) {
          return _c('tr', {
            key: x
          }, [_c('td', [_vm._v(" " + _vm._s(ts["name"] + " / " + ts["schoolname"]) + " "), _c('br'), (ts['usid'] < 1 || ts['usid'] > 0 && _vm.user.us == ts['usid'] ? false : true) ? _c('span', {
            staticClass: "font-italic red--text"
          }, [_vm._v(_vm._s(_vm.user.us) + "其他用户已关联")]) : _vm._e()]), _c('td', {
            staticStyle: {
              "max-width": "140px",
              "width": "130px"
            },
            attrs: {
              "align": "right"
            }
          }, [_c(VBtn, {
            attrs: {
              "small": "",
              "disabled": !(ts['usid'] < 1 || ts['usid'] > 0 && _vm.user.us == ts['usid']),
              "color": ts['slink'] === true ? 'success' : ''
            },
            on: {
              "click": function ($event) {
                return _vm.change(['teacher', ts]);
              }
            }
          }, [_vm._v(" " + _vm._s(ts["slink"] === true ? "已关联" : "未关联"))])], 1)]);
        }), 0)];
      },
      proxy: true
    }], null, false, 1167581345)
  })], 1) : _vm._e(), (_vm.student && Object.keys(_vm.student).length > 0 ? true : false) ? _c(VCard, {
    staticClass: "mb-6"
  }, [_c(VSystemBar, {
    attrs: {
      "color": "indigo lighten-2",
      "dark": ""
    }
  }), _c(VToolbar, {
    attrs: {
      "color": "deep-purple lighten-4",
      "elevation": "1"
    }
  }, [_c(VIcon, {
    staticClass: "mr-4"
  }, [_vm._v("mdi-account-network-outline")]), _c(VToolbarTitle, [_vm._v("相关联的学生身份")])], 1), _c(VSimpleTable, {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', _vm._l(_vm.student, function (ts, x) {
          return _c('tr', {
            key: x
          }, [_c('td', [_vm._v(" " + _vm._s(ts["name"] + " / " + ts["schoolname"]) + " "), _c('br'), (ts['usid'] < 1 || ts['usid'] > 0 && _vm.user.us == ts['usid'] ? false : true) ? _c('span', {
            staticClass: "font-italic red--text"
          }, [_vm._v(_vm._s(_vm.user.us) + "其他用户已关联")]) : _vm._e()]), _c('td', {
            staticStyle: {
              "max-width": "140px",
              "width": "130px"
            },
            attrs: {
              "align": "right"
            }
          }, [_c(VBtn, {
            attrs: {
              "small": "",
              "color": ts['slink'] === true ? 'success' : ''
            },
            on: {
              "click": function ($event) {
                return _vm.change(['student', ts]);
              }
            }
          }, [_vm._v(" " + _vm._s(ts["slink"] === true ? "已关联" : "未关联"))])], 1)]);
        }), 0)];
      },
      proxy: true
    }], null, false, 2190912239)
  })], 1) : _vm._e()], 1)], 1)], 1)], 1), _c(VDialog, {
    attrs: {
      "persistent": "",
      "max-width": "390"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "text-h5",
    class: _vm.errColor
  }, [_c('span', [_vm._v(_vm._s(_vm.errTitle))])]), _c(VCardText, {
    class: _vm.errColor
  }, [_vm._v(_vm._s(_vm.errMsg))]), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "error",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" 取消 ")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "success",
      "elevation": "0"
    },
    on: {
      "click": _vm.saveInfo
    }
  }, [_c(VIcon, [_vm._v("mdi-check")]), _vm._v(" 确认修改 ")], 1)], 1)], 1)], 1), _c(VDialog, {
    attrs: {
      "persistent": "",
      "max-width": "390"
    },
    model: {
      value: _vm.authDialog,
      callback: function ($$v) {
        _vm.authDialog = $$v;
      },
      expression: "authDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "text-h5"
  }, [_vm._v(" 改变用户身份提醒 ")]), _c(VCardText, [_vm._v("你的用户身份发生变化，这将影响你的相关功能，除非你确认需要进行更改。")]), _c(VCardActions, [_c(VBtn, {
    on: {
      "click": function ($event) {
        _vm.authDialog = false;
      }
    }
  }, [_vm._v(" 取消 ")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "error darken-1",
      "dark": ""
    },
    on: {
      "click": _vm.authChange
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-check")]), _vm._v(" 确认变更身份 ")], 1)], 1)], 1)], 1), _c(VSnackbar, {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        attrs
      }) {
        return [_c(VBtn, _vm._b({
          attrs: {
            "text": ""
          },
          on: {
            "click": function ($event) {
              _vm.snackbar = false;
            }
          }
        }, 'v-btn', attrs, false), [_c(VIcon, [_vm._v("mdi-close")])], 1)];
      }
    }]),
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")])], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };