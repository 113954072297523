//import { Button } from 'element-ui'
//import 'element-ui/lib/theme-chalk/index.css';
import incHeader from "../inc-header.vue";
export default {
  components: {
    incHeader
  },
  props: {
    mobile: Boolean
  },

  data() {
    return {
      menu: [],
      userinfo: {
        us: "用户ID",
        userid: "登录ID",
        name: "姓名",
        sex: "性别",
        idcard: "身份证号码"
      },
      radios: 1,
      user: {
        us: null,
        userid: null,
        name: null,
        idcard: null,
        sex: null
      },
      teachers: {},
      student: {},
      disabled: false,
      dialog: false,
      authDialog: false,
      authItem: "",
      errTitle: "系统提示",
      errMsg: "你确认要提交用户信息吗？用提交后无法修改。",
      errColor: "",
      snackbar: false,
      snackbarText: ""
    };
  },

  mounted() {
    this.usinfo();
  },

  methods: {
    usinfo() {
      //let usCookie = localStorage.getItem("usCookie")
      let data = {
        module: "usinfo",
        title: "info",
        data: {}
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        //console.log(res);
        //let user=JSON.parse(res.data)
        that.user = res.data.usinfo;

        if (that.user.idcard != "" && that.user.name != "") {
          that.disabled = true;
        }

        if (res.data.usinfo.teacher && Object.keys(res.data.usinfo.teacher.data).length > 0) {
          that.teachers = res.data.usinfo.teacher.data;

          for (let x in that.teachers) {
            that.teachers[x]["slink"] = that.teachers[x]["link"] == 1 ? true : false;
          }

          that.offsetSm = 0; ////console.log(that.teachers)
          //that.offsetSm = Object.keys(that.teachers).length
        }

        if (res.data.usinfo.student && Object.keys(res.data.usinfo.student.data).length > 0) {
          that.student = res.data.usinfo.student.data;

          for (let x in that.student) {
            that.student[x]["slink"] = that.student[x]["link"] == 1 ? true : false;
          } ////console.log(that.student)


          that.offsetSm = 0;
        } //that.offsetSm = Object.keys(that.student).length

      });
    },

    userItem(x, ts) {
      switch (x) {
        case "sex":
          return ts == 1 ? "男" : "女";

        default:
          return ts;
      }
    },

    saveInfo() {
      let data = {
        module: "usinfo",
        title: "sinfo",
        data: {
          name: this.user.name,
          sex: this.user.sex,
          idcard: this.user.idcard,
          area: "cn"
        }
      }; ////console.log(data)

      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        ////console.log(res)
        if (res.data.usinfo.errcode > 0) {
          that.errTitle = "错误";
          that.errColor = "red--text";
          that.errMsg = res.data.usinfo.errmsg;
        } else {
          that.dialog = false;
          that.snackbar = true;
          that.snackbarText = "保存成功！";
          that.disabled = true;
          that.usinfo();
        }
      });
    },

    change(ts) {
      this.authItem = ts;

      if (ts[1]["link"] < 1) {
        this.authChange();
      } else {
        this.authDialog = true;
      }
    },

    authChange() {
      let ts = this.authItem; //console.log(ts);

      let data = {
        module: "usinfo",
        title: "linkUser",
        data: {
          type: ts[0],
          id: ts[1]["id"]
        }
      }; //this.$forceUpdate();

      let that = this;
      this.$sopen.requestUscenterApi(data).then(function () {
        ////console.log(res);
        //that.usinfo();
        that.authDialog = false;
        window.location.reload();
      });
    }

  },

  created() {//this.$parent.$parent.$parent.selectedItem = 0;
  },

  watch: {
    dialog(oldName, newName) {
      if (newName == false) {
        this.errColor = "";
        this.errTitle = "系统提示";
        this.errMsg = "你确认要提交用户信息吗？用提交后无法修改。";
      }
    }

  }
};